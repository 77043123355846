/* Provide sufficient contrast against white background */
a {
    /*  color: #0366d6;*/
    color: white;
}

html {
    /*    background-color: rgb(35,31,32);*/
    background-color: transparent;
    color: white;
    /*    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
/*    font-family: Aria, 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
    font-family: "Arial Black";
}

body {
/*    background-color: rgb(35,31,32);*/
    color: white;
}

code {
    color: #E01A76;
}

.inLineImage {
    width: 40%;
    height: auto;
    margin: auto;
    display: inline-block;
    /*    float:left;*/
    margin-left: 5%;
    margin-right: 5%;
    vertical-align: middle;
}

.inLineText {
    width: 40%;
    height: auto;
    display: inline-block;
    padding: 0;
    /*    float: left;*/
    margin-left: 5%;
    margin-right: 5%;
    vertical-align: middle;
}

.inLineText h1{
    font-size:xx-large;
    padding-bottom:30px;

}

.block{
    margin-top:5%;
}

#heroDiv {
    /*    background-image: url("/images/screenshot2.png");*/
    height: 80vh;
    width: 100%;
    background-size: 100%;
    position: relative;
    z-index: 1;
/*    border-radius:10px;*/
    /*    display: block;*/
}

    #heroDiv video, #heroDivFooter video {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -10;
        /*        border-radius:10px;*/
        /*        display:*/
    }

.backgroundImage {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -10;
    /*        border-radius:10px;*/
    /*        display:*/
}

#heroDivFooter {
/*    background-image: url("/images/screenshot3.png");*/
    height: 80vh;
    width: 100%;
    background-size: 100%;
    margin-top: 100px;
/*    display: block;*/
    position: relative;
}

#heroButtons {
    width: 80%;
    margin: auto;
    display: block;
    z-index: 1;
}

.nav-link {
    display: inline-block;
}

#journalPage label{
    width:100%;
    height:auto;
    font-size:medium;
    color:white;
}

@media (min-width: 800px) {
    .heroButton, .heroButtonTwo, .heroButtonThree {
        font-size: x-large;
        width: 20%;
        border: 5px solid white;
    }

    .inLineText, .inLineImage {
        width: 40%;
    }

    }

@media (max-width: 800px) {

    .heroButton, .heroButtonTwo, .heroButtonThree {
        font-size: x-small;
        width: 20%;
        border: 2px solid white;
        margin:0;
    }

    .inLineText, .inLineImage {
        width: 80%;
    }

}

.heroButton, .heroButtonTwo, .heroButtonThree {
    padding: 2px;
    z-index: 5;
    text-align: center;
    margin-top: 5%;
    font-family: "Arial Black";
    background-color: rgba(0,0,0,0.3);
}

.heroButton {
    margin-left: 25%;
    margin-right: 5%;
}


.heroButtonTwo {
    margin-left: 5%;
    margin-right: 25%;
}

.heroButtonThree {
    margin-left: 40%;
    margin-right: 40%;
    margin-bottom: 5%;
}

.logoHeroSmall {
    z-index: 5;
    width: 20%;
    height: auto;
    margin-left: 2vw;
    padding-top: 2vw;
}

.logoHeroMain {
    /*    border: 1px solid white;*/
    display: block;
    margin: auto;
    padding-top: 100px;
    z-index: 5;
    width: 40%;
 
}

button.mmButtonIcon {
    background-color: transparent;
    border: none;
    vertical-align: central;
    margin: auto;
    padding-top: 15px;
    /*    height:100%;*/
    padding-left: 10px;
    color: transparent;
    /*    padding-top:14px;*/
}

.video-responsive {
    position: relative;
    width: 100%;
    height: auto;
    border: none;
    margin: auto;
    display: block;
}

    .video-responsive iframe {
        height: 100%;
        width: 100%;
        position: relative;
    }


    button.mmButtonIcon img {
        /*        background-color: violet;*/
        width: 1.5vw;
        height: 2vw;
    }

    .AddressText label {
        text-overflow: clip;
        width: 2px;
        font-size: smaller;
        margin-top: 10px;
    }

    .centralText {
        text-align: center;
        font-size: x-large;
    }

        .centralText p {
            padding-top: 10px;
            text-align: center;
            font-size: medium;
        }

        .centralText h3 {
            text-align: center;
            font-size: medium !important;
        }

    .centerButton {
        margin: auto;
        display: block;
        width: 100px;
        height: 50px;
        font-size: medium;
        font-style: oblique;
        border-radius: 10px;
        border-color: white;
        box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
        /*    background-color: rgb(35,31,32);*/
        background-color: transparent;
        color: white;
    }

    .sliderDiv {
        /*    border:solid white;*/
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        /*    display:flex;*/
    }




    .minValLabel {
        float: left;
        /*    width:15%;*/
        /*    margin-right:0%;*/
        width: 12%;
    }

    .maxValLabel {
        float: right;
        /*    width: 15%;*/
        /*    margin-left: 0%;*/
        width: 22%;
        margin-left: 6px;
    }

    .vidPlayer {
        margin-top: 100px;
    }


    div {
        width: 100%;
        height: auto;
    }

        div.container {
            width: 100%;
            height: 100%;
            max-width: 100%;
            padding: 0px;
            /*    margin:0px;*/
        }

    .container label {
        visibility: hidden;
    }

    div.centralMainPage {
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
    }

    .mmButtonIcon img, button.mmButtonIcon {
        margin-left: auto !important;
        margin-right: auto !important;
        display: block;
        /*            width: 100%;*/
        /*            height: 100%;*/
        color: white;
        /*            margin-bottom:5px;*/
        /*            height: 70px;*/
    }

    /*        div.centralMainPage label {
            visibility:visible;
        }*/

    div.centralMainPage h3 {
        text-align: center;
        align-content: center;
    }

    .centralMainPage img {
        text-align: center;
        align-content: center;
        margin-left: 15%;
        margin-right: 15%;
        width: 70%;
    }

    @media (min-width: 800px) {
        div.panelL {
            float: left;
            /*        width: 40%;*/
            /*        flex: 50% 1;*/
            margin: 0% auto;
            height: 200px;
            padding-left: 10%;
            padding-right: 10%;
            display: block;
        }

        div.panelR {
            float: right;
            /*        width:40%;*/
            /*        flex: 50% 1;*/
            margin: 0% auto;
            padding-left: 10%;
            padding-right: 10%;
            display: block;
        }



        div.section {
            width: 100%;
            display: block; /* or inline-flex */
            float: left;
            margin-top: 20px;
            padding-top: 20px;
            height: 100%;
        }

        .collumnContainer {
            display: flex;
        }

        .collumnA, .collumnB, .collumnC {
            width: 30%;
            margin-left: 5%;
            margin-right: 5%;
            float: left;
        }

        .sliderDiv input {
            margin-top: 0px;
            border: solid white;
            width: 60%;
        }

        .sliderDiv {
            /*    border:solid white;*/
            width: 55%;
            margin: auto;
            display: block;
            /*    display:flex;*/
        }
    }

    .errorMessage {
        width: 100%;
        margin: auto;
        text-align: center;
    }

    @media (max-width: 800px) {
        div.panelR {
            float: right;
            flex: 100%;
            margin: auto;
            width: 100%;
            display: block;
        }

        div.panelL {
            flex: 100%;
            float: left;
            margin: auto;
            margin-top: 25px;
            display: block;
            width: 100%;
            /*        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);*/
        }

        .navbar-toggler {
            border: none;
        }

        div.mainPage {
            width: 100%;
            margin-left: 0%;
            margin-right: 0%;
        }

        div.section {
            width: 100%;
            display: block; /* or inline-flex */
            float: left;
            margin-top: 20px;
            padding-top: 20px;
        }

        .collumnContainer {
            display: block;
        }

        .collumnA, .collumnB, .collumnC {
            width: 100%;
            margin-left: 0%;
            margin-right: 0%;
            float: left;
        }

        .sliderDiv input {
            margin-top: 0px;
            border: solid white;
            width: 100%;
        }

        .minValLabel, .maxValLabel {
            display: none;
        }
    }



    .walletStuff {
        width: 100%;
        background-color: transparent;
        /*    margin: 10px auto;*/
        display: block;
        padding-bottom: 100px;
        padding-top: 0px;
        text-align: center;
    }

        .walletStuff button {
            /*    margin: auto;*/
            width: 120px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: white;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            background-color: transparent;
            /*        background-color: rgb(35,31,32);*/
            color: white;
            border: 1px solid white;
            margin: auto;
            display: block;
        }

    .mintSection {
        margin: auto;
        display: block;
        text-align: center;
        width: 100px;
        padding-top: 30px;
    }

        .mintSection button {
            margin: auto;
            display: block;
            width: 100px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: white;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            /*        background-color: rgb(35,31,32);*/
            border: 1px solid white;
            background-color: transparent;
            color: white;
            visibility: visible !important;
        }

        .mintSection label {
            margin: auto;
            display: block;
            width: 100px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: white;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            background-color: transparent;
            border: 1px solid white;
            /*        background-color: rgb(35,31,32);*/
            color: white;
            visibility: visible !important;
        }

    .modalDiv {
        visibility: hidden;
    }

    .social-bar {
        background-color: rgba(0,0,0,0.11);
        /*    //padding: 0px 0px;*/
        position: fixed;
        bottom: 0px;
        /*    left: 38%;*/
        height: 40px;
        /*padding-left:40%;*/
        /*margin:auto;*/
        /*display:block;*/
        width: 100%;
        display: flex;
    }

    .openseaIcon {
        padding-top: 2px;
    }

    .social-button-container {
        /*    margin:auto;*/
        /*    display:block;*/
        display: block;
        /*    width:10%;*/
        padding-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

    a.social {
        margin: 0 2rem;
        transition: transform 250ms;
        display: inline-block;
    }

    .auto-resizable-iframe {
        max-width: 100%;
        margin: 0px auto;
    }

        .auto-resizable-iframe > div {
            position: relative;
            padding-bottom: 75%;
            height: 0px;
        }

        .auto-resizable-iframe iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }


    div.imagepanel img, div.imagepanel iframe {
        width: 80%;
        height: auto;
        border: none;
        margin: auto;
        display: block;
    }

    .navBarLogo {
        width: 60px;
        height: 60px;
    }

    .navbar-toggler-icon {
        background-color: rgb(225,1,252);
        color: grey;
        border-radius: 5px;
    }



    .mini .menu-item a {
        color: white;
    }

    .wrapper {
        padding: 20px;
    }

    .largerLogo {
        width: 120px;
        height: 120px;
    }

    .centerInDiv {
        width: 100%;
    }


    button img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 5px;
    }

    h2 {
        margin-bottom: 20px !important;
        font-size: x-large !important;
    }

    ul {
        margin-left: 30px !important;
    }

    .text-light {
        font-size: x-large;
        color: white !important;
        margin: 5px;
        /*    background-color:pink;*/
    }

        .text-light :active {
            font-size: x-large;
            color: black !important;
            background-color: white;
            margin: 5px;
            border-radius: 10px;
            /*    background-color:pink;*/
        }

    table img {
        margin-right: 10px;
        width: 16px;
        height: 16px;
    }

    .instructionsSection {
        font-size: small;
    }

        .instructionsSection h3 {
            font-size: x-large;
        }

    .loadingText {
        width: 200px;
        float: left;
        color: dimgrey;
    }

    .fullscreenButton {
        background-color: transparent;
        color: white;
        float: right;
        border: none;
    }

    .react-player {
        width: 100%;
        margin: auto;
        height: 100%;
    }


    .btn-primary {
        /*  color: #fff;*/
        background-color: #1b6ec2;
        border-color: #1861ac;
    }
